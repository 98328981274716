/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'

const LazyLoadVideo = ({
  videoSrc,
  thumbnailSrc,
  thumbnailAlt,
  videoProps,
  imageOverlay = false,
}: {
  videoSrc: string
  thumbnailSrc: string
  thumbnailAlt: string
  videoProps?: JSX.IntrinsicElements['video']
  imageOverlay?: boolean
}) => {
  const videoContainerRef = useRef<any>(null)
  const [isVideoIsReady, setIsVideoIsReady] = useState(false)

  // サムネイルが読み込まれたらビデオをロードする
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // ビューポートに入った時の処理
            const videoElement = document.createElement('video')

            videoElement.src = videoSrc
            videoElement.addEventListener('loadeddata', () => {
              setIsVideoIsReady(true)
            })
            videoElement.load()
          } else {
            // ビューポートから外れた時の処理
            setIsVideoIsReady(false)
            // 1秒後setIsVideoLoaded(false);

            setIsVideoIsReady(false)
          }
        })
      },
      { threshold: 0.1 }
    )

    if (videoContainerRef.current) {
      observer.observe(videoContainerRef.current)
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (videoContainerRef.current) {
        observer.unobserve(videoContainerRef.current)
      }
    }
  }, [])

  return (
    <span ref={videoContainerRef}>
      {(isVideoIsReady || !imageOverlay) && (
        <video
          style={{ zIndex: 0 }}
          poster={thumbnailSrc}
          src={isVideoIsReady ? videoSrc : ''}
          onLoadedData={() => {
            setIsVideoIsReady(true)
          }}
          {...videoProps}
        />
      )}
      {imageOverlay && (
        <div
          style={{
            opacity: isVideoIsReady ? 0 : 1,
            transition: 'opacity 1.0s ease',
          }}
        >
          <Image
            className={'z-10 object-cover'}
            src={thumbnailSrc}
            alt={thumbnailAlt}
            layout="fill"
            objectFit="cover"
            loading="eager"
            sizes="50vw"
          />
        </div>
      )}
    </span>
  )
}

export default LazyLoadVideo
