const LPFooter = (): JSX.Element => {
  return (
    <footer>
      <div className="footCont">
        <a className="logo" css=":hover {opacity: 1 !important}">
          <img src="/landing_renewal/img/logo_yfm.svg" alt="ヤマトフードマーケット" />
        </a>
        <div className="inquiry">
          <h3>お問い合わせ</h3>
          <ul>
            <li>
              <a className="tel" href="tel:03-4564-2161">
                <img src="/landing_renewal/img/icon_tell_orange.svg" alt="tel" width="14" />
                03-4564-2161
              </a>
              <dl>
                <dt>営業時間</dt>
                <dd>
                  <span className="time">9:00-18:00</span>
                  <span className="note">（土日祝含む）</span>
                </dd>
              </dl>
            </li>
            <li>
              <a
                className="mail"
                href="m&#97;i&#108;t&#111;:y&#102;m&#48;&#51;0&#49;&#64;k&#117;&#114;&#111;&#110;e&#107;&#111;&#121;&#97;&#109;&#97;&#116;&#111;&#46;&#99;&#111;&#46;&#106;&#112;"
              >
                <img src="/landing_renewal/img/icon_mail_orange.svg" alt="mail" width="16" />
                メールでお問い合わせ
              </a>
              <dl>
                <dd>
                  <span className="time">
                    y&#102;m&#48;&#51;0&#49;&#64;k&#117;&#114;&#111;&#110;e&#107;&#111;&#121;&#97;&#109;&#97;&#116;&#111;&#46;&#99;&#111;&#46;&#106;&#112;
                  </span>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
        <nav>
          <ul>
            <li>
              <a href="#section_feature">
                <img src="/landing_renewal/img/icon_arrow_circle_orange.svg" alt="" />
                ３つの特徴
              </a>
            </li>
            <li>
              <a href="#section_recommend">
                <img src="/landing_renewal/img/icon_arrow_circle_orange.svg" alt="" />
                おすすめ食材
              </a>
            </li>
            <li>
              <a href="#section_voice">
                <img src="/landing_renewal/img/icon_arrow_circle_orange.svg" alt="" />
                お客様の声
              </a>
            </li>
          </ul>
        </nav>
        <ul className="policy">
          <li>
            <a href="https://www.kuronekoyamato.co.jp/ytc/corp/" target="_blank" rel="noreferrer">
              企業情報
            </a>
          </li>
          <li>
            <a href="/terms-and-conditions/" target="_blank">
              利用規約(飲食店様向け)
            </a>
          </li>
          <li>
            <a href="https://www.kuronekoyamato.co.jp/ytc/privacy/" target="_blank" rel="noreferrer">
              個人情報の取扱い
            </a>
          </li>
          <li>
            <a href="/scta/" target="_blank">
              特定商品取引に関する記載
            </a>
          </li>
          <li>
            <a
              href="https://lily-pear-58c.notion.site/Yamato-Food-Market-FAQ-8c3ef7f39f444ff3b8c79ad3c96b57f6"
              target="_blank"
              rel="noreferrer"
            >
              よくある質問
            </a>
          </li>
          <li className="icon">
            <a href="https://www.instagram.com/yamato_food_market/" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 26 26">
                <defs css=".cls-1{clip-path:url(#clippath);}.cls-2{fill:none;}.cls-2,.cls-3{stroke-width:0px;}">
                  <clipPath id="clippath">
                    <rect className="cls-2" y="0" width="26" height="26" />
                  </clipPath>
                </defs>
                <g className="cls-1">
                  <path
                    className="cls-3"
                    d="m13,2.34c3.47,0,3.88.01,5.25.08,1.27.06,1.96.27,2.41.45.57.21,1.08.54,1.5.97.43.42.76.93.97,1.5.18.46.39,1.15.45,2.41.06,1.37.08,1.78.08,5.25s-.01,3.88-.08,5.25c-.06,1.27-.27,1.96-.45,2.41-.22.56-.55,1.07-.97,1.49s-.93.76-1.49.97c-.46.18-1.15.39-2.41.45-1.37.06-1.78.08-5.25.08s-3.88-.01-5.25-.08c-1.27-.06-1.96-.27-2.41-.45-.57-.21-1.08-.54-1.5-.97-.43-.42-.76-.93-.97-1.5-.18-.46-.39-1.15-.45-2.41-.06-1.37-.08-1.78-.08-5.25s.01-3.88.08-5.25c.06-1.27.27-1.96.45-2.41.21-.57.54-1.08.97-1.5.42-.43.93-.76,1.5-.97.46-.18,1.15-.39,2.41-.45,1.37-.06,1.78-.08,5.25-.08m0-2.34c-3.53,0-3.97.01-5.36.08-1.39.06-2.33.28-3.16.61-.87.33-1.65.84-2.3,1.5-.66.65-1.17,1.44-1.5,2.3-.32.83-.54,1.77-.6,3.16-.06,1.38-.08,1.83-.08,5.36s.01,3.97.08,5.36c.06,1.39.28,2.33.6,3.15.33.87.84,1.65,1.5,2.3.65.66,1.44,1.17,2.3,1.5.83.32,1.77.54,3.16.6,1.38.06,1.83.08,5.36.08s3.97-.01,5.36-.08c1.39-.06,2.33-.28,3.15-.6.86-.33,1.65-.84,2.3-1.5.65-.65,1.17-1.44,1.5-2.3.32-.83.54-1.77.6-3.16.06-1.38.08-1.83.08-5.36s-.01-3.97-.08-5.36c-.06-1.39-.28-2.33-.6-3.15-.33-.87-.84-1.65-1.5-2.3-.65-.66-1.44-1.17-2.3-1.5-.83-.32-1.77-.54-3.16-.6C16.98.02,16.53,0,13,0h0Z"
                  />
                  <path
                    className="cls-3"
                    d="m13,6.33c-1.32,0-2.61.39-3.71,1.13-1.1.73-1.95,1.78-2.46,3s-.64,2.56-.38,3.86c.26,1.29.89,2.48,1.83,3.42.93.93,2.12,1.57,3.42,1.83,1.3.26,2.64.13,3.86-.38,1.22-.51,2.26-1.36,3-2.46.73-1.1,1.12-2.39,1.12-3.71,0-.88-.17-1.74-.51-2.55-.34-.81-.83-1.55-1.45-2.17-.62-.62-1.36-1.11-2.17-1.45-.81-.34-1.68-.51-2.55-.51Zm0,11.01c-.86,0-1.7-.25-2.41-.73-.71-.48-1.27-1.15-1.6-1.95-.33-.79-.41-1.66-.25-2.5.17-.84.58-1.61,1.19-2.22.61-.61,1.38-1.02,2.22-1.19.84-.17,1.71-.08,2.5.25.79.33,1.47.88,1.95,1.6.48.71.73,1.55.73,2.41,0,1.15-.46,2.25-1.27,3.07-.81.81-1.92,1.27-3.07,1.27Z"
                  />
                  <path
                    className="cls-3"
                    d="m19.94,7.62c.86,0,1.56-.7,1.56-1.56s-.7-1.56-1.56-1.56-1.56.7-1.56,1.56.7,1.56,1.56,1.56Z"
                  />
                </g>
              </svg>
            </a>
          </li>
        </ul>
      </div>
      <div className="copyright">
        <a href="https://www.kuronekoyamato.co.jp/" target="_blank" rel="noreferrer">
          <img src="/landing_renewal/img/logo_yamato.svg" alt="ヤマト運輸" width="97" />
        </a>
        &copy;2024 Yamato Transport Co., Ltd. All Rights Reserved.
      </div>
    </footer>
  )
}

export default LPFooter
