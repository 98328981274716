import { RedirectLoginOptions } from '@auth0/auth0-react'
import { useCallback, useState } from 'react'
import { UserType } from 'src/generated/graphql'

export type SignUpModalHooks = {
  isOpenSignUpModal: boolean
  handleBuyerSignUp: () => void
  handleOpenSignUpModal: () => void
  handleCloseSignUpModal: () => void
}

export const useSignUpModal = (
  loginWithRedirect: (options?: RedirectLoginOptions | undefined) => Promise<void>
): SignUpModalHooks => {
  const [isOpenSignUpModal, setIsOpenModal] = useState(false)
  const handleBuyerSignUp = useCallback(() => {
    loginWithRedirect({
      role: UserType.Buyer,
      screen_hint: 'signup',
      type: 'signup',
    })
  }, [loginWithRedirect])

  const handleOpenSignUpModal = (): void => {
    setIsOpenModal(true)
  }
  const handleCloseSignUpModal = (): void => {
    setIsOpenModal(false)
  }

  return {
    isOpenSignUpModal,
    handleBuyerSignUp,
    handleOpenSignUpModal,
    handleCloseSignUpModal,
  }
}
