import Head from 'next/head'

const GuestHead = (): JSX.Element => {
  return (
    <Head>
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <title>ヤマトフードマーケット</title>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Edu+TAS+Beginner:wght@500&family=Jost:wght@500&family=Noto+Serif+JP:wght@500;600&family=Zen+Kaku+Gothic+New:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link rel="shortcut icon" href="/landing_renewal/img/favicon.ico" />
    </Head>
  )
}

export default GuestHead
