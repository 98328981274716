import { SignUpModalHooks } from 'src/components/SignUpModal/useSignUpModal'
import Script from 'next/script'

type Props = {
  handleOpenSignUpModal: SignUpModalHooks['handleOpenSignUpModal']
  handleLogin: SignUpModalHooks['handleBuyerSignUp']
}

export const Header = ({ handleOpenSignUpModal, handleLogin }: Props): JSX.Element => {
  return (
    <>
      <Script type="text/javascript" src="/landing_renewal/js/script.js" strategy="afterInteractive"></Script>
      <header>
        <div className="headerIn">
          <ul className="resist">
            <li className="black">
              <a onClick={handleLogin} css="cursor: pointer;">
                <div className="img">
                  <img src="/landing_renewal/img/icon_user_line.svg" alt="" />
                </div>
                <div className="txt">
                  <span className="jp">ログイン</span>
                  <span className="en">login</span>
                </div>
              </a>
            </li>
            <li className="orange">
              <a onClick={handleOpenSignUpModal} css="cursor: pointer;">
                <div className="img">
                  {/* <!-- <img src="/landing_renewal/img/icon_user.svg" alt=""> --> */}
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.37 19.67">
                    <defs></defs>
                    <path
                      className="icon_user"
                      d="m8.18,8.18c2.26,0,4.09-1.83,4.09-4.09S10.44,0,8.18,0s-4.09,1.83-4.09,4.09,1.83,4.09,4.09,4.09Z"
                    />
                    <path
                      className="cls-1"
                      d="m16.37,15.07c0,2.54,0,4.6-8.18,4.6S0,17.61,0,15.07s3.66-4.6,8.18-4.6,8.18,2.06,8.18,4.6Z"
                    />
                  </svg>
                </div>
                <div className="txt">
                  <span className="jp">会員登録</span>
                  <span className="en">signup</span>
                </div>
              </a>
            </li>
          </ul>
          <div className="navbtnCont">
            <a className="nav-button">
              <span></span>
              <span></span>
              <span>menu</span>
            </a>
          </div>
          <nav>
            <div>
              <div className="logo">
                <img src="/landing_renewal/img/logo_yfm.svg" alt="ヤマトフードマーケット" />
              </div>
              <ul>
                <li>
                  <a href="#section_feature">
                    <span>
                      <img src="/landing_renewal/img/icon_arrow_circle_orange.svg" alt="" />
                      ３つの特徴
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#section_recommend">
                    <span>
                      <img src="/landing_renewal/img/icon_arrow_circle_orange.svg" alt="" />
                      おすすめ食材
                    </span>
                  </a>
                </li>
                <li>
                  <a href="#section_voice">
                    <span>
                      <img src="/landing_renewal/img/icon_arrow_circle_orange.svg" alt="" />
                      お客様の声
                    </span>
                  </a>
                </li>
              </ul>
              <a
                className="insta"
                href="https://www.instagram.com/yamato_food_market/"
                rel="noreferrer"
                target="_blank"
              >
                <img src="/landing_renewal/img/icon_instagram.svg" width="16" alt="instagram" />
                公式<span>Instagram</span>
              </a>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}
