import { ReactNode } from 'react'

import GuestHead from '../GuestHead/GuestHead'
import GuestFooter from '../GuestFooter/GuestFooter'
import { useRouter } from 'next/router'
import { Route } from 'src/consts/route'
import LPFooter from '../LPFooter/LPFooter'

type Props = {
  children: ReactNode
}

const Guest = ({ children }: Props): JSX.Element => {
  const { pathname } = useRouter()
  const footer = pathname === Route.landing ? <LPFooter /> : <GuestFooter />
  return (
    <>
      <GuestHead />
      {children}
      {footer}
    </>
  )
}

export default Guest
