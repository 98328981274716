import tw from 'twin.macro'
import React from 'react'

interface Props {
  onClose: () => void
  onSignUp: () => void
}
export function SignUpModal({ onClose, onSignUp }: Props) {
  return (
    <Overlay>
      <div
        tw="relative flex flex-col items-center rounded-2xl bg-white pt-20 pb-20 pr-10 pl-10 w-7/12 text-2xl leading-10"
        css="max-width: 512px; min-width: 350px;"
      >
        <div tw="flex flex-col justify-between items-center" css="max-width: 412px;">
          <div tw="absolute top-4 right-4 flex cursor-pointer p-4" onClick={onClose}>
            <img src="/icon_close2.svg" tw="w-8" />
          </div>
          <div tw="text-justify">
            <p tw="">飲食店様へのサービス対象エリアは以下になります。</p>
            <p tw="mt-4">世田谷区・品川区・千代田区・新宿区・渋谷区・港区・中央区・目黒区・大田区</p>
            <p tw="mt-10 font-bold">
              上記のエリア以外の飲食店様は、ご登録頂いても現時点では商品のご購入・お届けはできかねますのでご了承下さい。
            </p>
          </div>
          <div tw="mt-20">
            <ButtonOrange
              onClick={onSignUp}
              css="padding: 10px 40px; font-weight: 700; font-size: 16px; transition: all .3s; border: solid 2px #FC771C;"
            >
              登録へ進む
            </ButtonOrange>
          </div>
        </div>
      </div>
    </Overlay>
  )
}

const Overlay = tw.div`fixed top-0 left-0 flex justify-center items-center w-full h-full z-10 bg-black bg-opacity-75`
const ButtonOrange = tw.button`bg-orange text-white rounded-full hover:bg-white hover:text-orange`
