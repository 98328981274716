/* eslint-disable react-hooks/exhaustive-deps */
import { SignUpModalHooks } from 'src/components/SignUpModal/useSignUpModal'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import LazyLoadVideo from 'src/components/LazyLoadVideo/LazyLoadVideo'
import { SERVICE_FEE_PRICE } from 'src/domain/serviceFee'
import { useRouter } from 'next/router'

/* 成果物に影響あるので.prettierignoreに入れてる */
type Props = {
  handleOpenSignUpModal: SignUpModalHooks['handleOpenSignUpModal']
}

export const Content = ({ handleOpenSignUpModal }: Props): JSX.Element => {
  const router = useRouter()
  const currentTab = router.query.tab || 'voice1'
  const currentPin = router.query.pin || null

  const [videoIsReadyHero, setVideoIsReadyHero] = useState(false)


  useEffect(() => {
    if (!router.query.pin && !router.query.tab) return
    setTimeout(() => {
      const target = currentPin ? document.querySelector(`#section_recommend .serif`) : document.querySelector(`#section_voice .serif`)

      if (target) target.scrollIntoView({ behavior: 'smooth' })
    }, 1000)
  }),
    [currentTab, currentPin]

  return (
    <main>
      <div id="hero">
        <div className="txt">
          <div className="read">
            こだわりの食材が簡単に仕入れられる！
            <strong>飲食店限定の新しい産直サポートサービス</strong>
          </div>
          <h1 className="logo">
            <img
              src="/landing_renewal/img/logo_yfm.svg"
              alt="ヤマトフードマーケット"
            />
          </h1>
        </div>
        <div className="movie">
          <video
            className="z-0"
            autoPlay
            muted
            playsInline
            loop
            onLoadedData={() => {
              setVideoIsReadyHero(true)
            }}
          >
            <source src="/landing_renewal/img/hero.mp4" type="video/mp4" />
          </video>
          <div
            style={{
              opacity: videoIsReadyHero ? 0 : 1,
              transition: 'opacity 1.0s ease',
            }}
          >
            <Image
              className={
                'w-full h-full z-10 object-cover transition-opacity duration-500 opacity:ease-in-out'
              }
              src="/landing_renewal/img/hero.jpg"
              alt="ヤマトフードマーケット・トップ動画・アイコン"
              layout="fill"
              objectFit="cover"
              loading="eager"
              sizes="100vw"
              quality={100}
            />
          </div>
        </div>
        <div className="campaign">
          <a onClick={handleOpenSignUpModal} css="cursor: pointer;">
            <img
              src="/landing_renewal/img/badge_free_fee.png"
              alt="新規ご登録特典60日間利用料無料！"
            />
          </a>
        </div>
        <div className="scroll">
          <a href="#section_message">
            Scroll
            <img
              src="/landing_renewal/img/icon_downarrow_w.svg"
              width="12"
              alt=""
            />
          </a>
        </div>
      </div>
      <section id="section_message">
        <div className="txtcont">
          <h2>
            食材にこだわりたい、
            <br />
            飲食店の皆様へ。
          </h2>
          <div className="txt">
            <p>
              食材にこだわると、思い浮かぶ<strong>「産直」</strong>
            </p>
            <p>
              生産者探し…
              <br />
              数量、ロットなどの交渉…
              <br />
              手間が掛かるやりとり…
              <br />
              高額になりがちな送料…
            </p>
            <p>
              そんな課題を感じる飲食店の皆様のために、
              <br />
              ヤマト運輸から新しい産直サービスのご提案です。
            </p>
            <p>
              その名も<strong>「ヤマトフードマーケット」</strong>
              <br />
            </p>
            <p>
              このサービスが食材を育む人、料理を作る人、
              <br />
              そして味わう人にとって、
              <br />
              豊かな食の架け橋となりますように。
            </p>
          </div>
          {/* <!-- <div className="sign">
          <img className="pc" src="/landing_renewal/img/message_sign_pc.svg" alt="ヤマトフードマーケットチーム">
          <img className="sp" src="/landing_renewal/img/message_sign_sp.svg" alt="ヤマトフードマーケットチーム">
        </div> --> */}
        </div>
        <ul className="message_slide">
          <li>
            <img
              src="/landing_renewal/img/message_img4.jpg"
              alt="レモンを収穫する農家の方"
            />
          </li>
          <li>
            <img
              src="/landing_renewal/img/message_img1.jpg"
              alt="食材のチェックをするシェフ"
            />
          </li>
          <li>
            <img
              src="/landing_renewal/img/message_img3.jpg"
              alt="牡蠣を水揚げする漁師の方"
            />
          </li>
          <li>
            <img
              src="/landing_renewal/img/message_img5.jpg"
              alt="料理を提供するシェフ"
            />
          </li>
          <li>
            <img
              src="/landing_renewal/img/message_img2.jpg"
              alt="ミニトマトを収穫する農家の方"
            />
          </li>
        </ul>
      </section>
      <section id="section_feature">
        <h2 className="serif">
          <span>Feature</span>
          <br />
          <strong>3つの特徴</strong>
        </h2>
        <ul className="point">
          <li>
            <div className="num">1</div>
            <div className="img"></div>
            <h3>
              <strong>
                <span className="bgW">全国の選り</span>
              </strong>
              <br className="sp" />
              <span className="bgW">
                <strong>すぐりの食材</strong>を､
              </span>
              <br />
              <span className="bgW">
                <strong>新鮮な状態</strong>で
              </span>
              <br className="sp" />
              <span className="bgW">お届け。</span>
            </h3>
            <p>
              約50年に渡る宅急便事業で培われた生産者とのコネクションを土台に、食材に精通した専門家が吟味。経験豊富な専門スタッフによる目利きで､日本各地からこだわりの食材を提供します。ヤマトだからこそ実現できる｢鮮度｣のよさにもご注目ください。
            </p>
          </li>
          <li>
            <div className="num">2</div>
            <div className="img"></div>
            <h3>
              <strong>
                <span className="bgW">利用料一律 </span>
              </strong>
              <br className="sp" />
              <span className="bgW">
                <strong>
                  <span className="underline">
                    <span className="price">
                      1<span className="comma">,</span>500
                    </span>
                    <span className="yen"> 円 </span>
                  </span>
                </strong>
                <span className="tax">(税抜)</span>
              </span>
              <br />
              <span className="bgW">日本全国どこでも､</span>
              <br className="sp" />
              <span className="bgW">何品でも｡</span>
            </h3>
            <p>
              {`北海道から九州まで、何品ご注文いただいても、1カートにつき${SERVICE_FEE_PRICE.Cartly.toLocaleString()}円(税抜)で店舗までお届けします。納品日も指定できるので、計画的に、ムダなく食材を仕入れることが可能です。登録料なども一切かかりません。`}
              <br />
              <span style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                {`※色々な商品を頻繁に注文したい方向けの「月額定額プラン${SERVICE_FEE_PRICE.Monthly.toLocaleString()}円（税抜）」もあります。`}
              </span>
            </p>
          </li>
          <li>
            <div className="num">3</div>
            <div className="img"></div>
            <h3>
              <span className="bgW">
                <strong>スマホひとつ</strong>で､
              </span>
              <br className="sp" />
              <span className="bgW">新たな食材探し｡</span>
              <br />
              <span className="bgW">今日からあなたも</span>
              <br className="sp" />
              <strong>
                <span className="bgW">産直をスタート</span>
              </strong>
            </h3>
            <p>
              全国900以上の生産者のもとから、気になる食材をスマホやパソコンで簡単に仕入れることができます。新しい生産者や食材との出会いを通じて、貴店のメニュー開発や品質向上のお役に立てれば幸いです。
            </p>
          </li>
        </ul>
      </section>
      <section id="section_recommend">
        <h2 className="serif">
          <span>Recommendation</span>
          <br />
          <strong>おすすめ食材</strong>
        </h2>
        <p className="read">
          季節ごとの旬な食材から、
          <br className="sp" />
          通年仕入れ可能な食材まで常時2,000商品以上掲載中。
          <br />
          <strong>こだわり抜かれた食材</strong>の中から
          <br className="sp" />
          おすすめの食材を紹介します。
        </p>
        <ul className="foodlist">
          <li>
            <a href="#section_regist">
              <div className="place">
                <span>愛媛県</span>
              </div>
              <div className="imgcont">
                <Image
                  src="/landing_renewal/img/recommend/food1.jpg"
                  alt="瀬戸田レモン"
                  layout="fill"
                  objectFit="cover"
                  sizes="50vw"
                />
                <div className="ttl">瀬戸田レモン</div>
              </div>
              <div className="farmer">
                <div className="icon">
                  <img
                    src="/landing_renewal/img/recommend/farmer1.jpg"
                    alt="生産者"
                  />
                </div>
                <div className="name">
                  わらしべ｡<span>さん</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#section_regist">
              <div className="place">
                <span>鹿児島県</span>
              </div>
              <div className="imgcont">
                <Image
                  src="/landing_renewal/img/recommend/food2.jpg"
                  alt="そら豆"
                  layout="fill"
                  objectFit="cover"
                  sizes="50vw"
                />
                <div className="ttl">そら豆</div>
              </div>
              <div className="farmer">
                <div className="icon">
                  <img
                    src="/landing_renewal/img/recommend/farmer2.jpg"
                    alt="生産者"
                  />
                </div>
                <div className="name">
                  アグリスタイル<span>さん</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#section_regist">
              <div className="place">
                <span>秋田県</span>
              </div>
              <div className="imgcont">
                <Image
                  src="/landing_renewal/img/recommend/food3.jpg"
                  alt="三関セリ"
                  layout="fill"
                  objectFit="cover"
                  sizes="50vw"
                />
                <div className="ttl">三関セリ</div>
              </div>
              <div className="farmer">
                <div className="icon">
                  <img
                    src="/landing_renewal/img/recommend/farmer3.jpg"
                    alt="生産者"
                  />
                </div>
                <div className="name">
                  あいば商店<span>さん</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#section_regist">
              <div className="place">
                <span>宮城県</span>
              </div>
              <div className="imgcont">
                <Image
                  src="/landing_renewal/img/recommend/food4.jpg"
                  alt="真牡蠣"
                  layout="fill"
                  objectFit="cover"
                  sizes="50vw"
                />
                <div className="ttl">真牡蠣</div>
              </div>
              <div className="farmer">
                <div className="icon">
                  <img
                    src="/landing_renewal/img/recommend/farmer4.jpg"
                    alt="生産者"
                  />
                </div>
                <div className="name">
                  奥松島水産<span>さん</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#section_regist">
              <div className="place">
                <span>高知県</span>
              </div>
              <div className="imgcont">
                <Image
                  src="/landing_renewal/img/recommend/food5.jpg"
                  alt="鮮魚BOX"
                  layout="fill"
                  objectFit="cover"
                  sizes="50vw"
                />
                <div className="ttl">鮮魚BOX</div>
              </div>
              <div className="farmer">
                <div className="icon">
                  <img
                    src="/landing_renewal/img/recommend/farmer5.jpg"
                    alt="生産者"
                  />
                </div>
                <div className="name">
                  KUISHI<span>さん</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#section_regist">
              <div className="place">
                <span>鹿児島県</span>
              </div>
              <div className="imgcont">
                <Image
                  src="/landing_renewal/img/recommend/food6.jpg"
                  alt="黒さつま鶏のさしみーと"
                  layout="fill"
                  objectFit="cover"
                  sizes="50vw"
                />
                <div className="ttl">
                  <span className="small">黒さつま鶏の</span>さしみーと
                </div>
              </div>
              <div className="farmer">
                <div className="icon">
                  <img
                    src="/landing_renewal/img/recommend/farmer6.jpg"
                    alt="生産者"
                  />
                </div>
                <div className="name">
                  カゴシマバンズ<span>さん</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#section_regist">
              <div className="place">
                <span>佐賀県</span>
              </div>
              <div className="imgcont">
                <div
                  className="img"
                  css="background-image: url(/landing_renewal/img/recommend/food7.jpg); background-size: cover;"
                ></div>
                <Image
                  src="/landing_renewal/img/recommend/food7.jpg"
                  alt="佐賀牛"
                  layout="fill"
                  objectFit="cover"
                  sizes="50vw"
                />
                <div className="ttl">佐賀牛</div>
              </div>
              <div className="farmer">
                <div className="icon">
                  <img
                    src="/landing_renewal/img/recommend/farmer7.jpg"
                    alt="生産者"
                  />
                </div>
                <div className="name">
                  桑原畜産<span>さん</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="#section_regist">
              <div className="place">
                <span>兵庫県</span>
              </div>
              <div className="imgcont">
                <Image
                  src="/landing_renewal/img/recommend/food9.jpg"
                  alt="あきたこまち（米）"
                  layout="fill"
                  objectFit="cover"
                  sizes="50vw"
                />
                <div className="ttl">あきたこまち（米）</div>
              </div>
              <div className="farmer">
                <div className="icon">
                  <img
                    src="/landing_renewal/img/recommend/farmer9.jpg"
                    alt="生産者"
                  />
                </div>
                <div className="name">
                  上月アグリファーマ株式会社<span>さん</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </section>
      <section id="section_voice">
        <h2 className="serif">
          <span>User Voice</span>
          <br />
          <strong>お客様の声</strong>
        </h2>
        <ul className="tab" id="topTab">
          <li>
            <button className={`tab-btn ${currentTab === 'voice1' ? 'current' : ''}`} data-target="voice1">
              <div
                className="img"
                css="background-image: url(/landing_renewal/img/voice/1_1.jpg);"
              ></div>
              <div className="num">
                Voice <strong>1</strong>
              </div>
              <div className="name">
                <div className="restaurant">
                  代官山ASO チェレステ
                  <br />
                  日本橋店
                </div>
                <div className="chef">
                  料理長 菊池<span>さん</span>
                </div>
              </div>
            </button>
          </li>
          <li>
            <button className={`tab-btn ${currentTab === 'voice2' ? 'current' : ''}`} data-target="voice2">
              <div
                className="img"
                css="background-image: url(/landing_renewal/img/voice/2_1.jpg);"
              ></div>
              <div className="num">
                Voice <strong>2</strong>
              </div>
              <div className="name">
                <div className="restaurant">Concerto</div>
                <div className="chef">
                  オーナーシェフ 井口<span>さん</span>
                </div>
              </div>
            </button>
          </li>
          <li>
            <button className={`tab-btn ${currentTab === 'voice3' ? 'current' : ''}`} data-target="voice3">
              <div
                className="img"
                css="background-image: url(/landing_renewal/img/voice/3_1.jpg);"
              ></div>
              <div className="num">
                Voice <strong>3</strong>
              </div>
              <div className="name">
                <div className="restaurant">日本料理 一凛</div>
                <div className="chef">
                  店主 橋本<span>さん</span>
                </div>
              </div>
            </button>
          </li>
        </ul>
        <article className={`voicecont voice1 ${currentTab === 'voice1' ? 'current' : ''}`} data-voice="voice1">
          <div>
            <div className="sliderCont">
              <ul className="slider_voice">
                <li>
                  <Image
                    src="/landing_renewal/img/voice/1_1.jpg"
                    alt="料理長 菊池さん"
                    layout="fill"
                    objectFit="cover"
                  />
                </li>
                <li>
                  <Image
                    src="/landing_renewal/img/voice/1_2.jpg"
                    alt="料理長 菊池さんの料理"
                    layout="fill"
                    objectFit="cover"
                  />
                </li>
              </ul>
            </div>
            <div className="movie_th">
              <button
                className="play"
                data-micromodal-trigger="modal-1"
                role="button"
              >
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                    <defs></defs>
                    <path
                      className="icon_play"
                      d="m20.86,29.51v-11.03c0-.14.03-.27.1-.39.07-.12.16-.22.27-.28.11-.07.24-.1.37-.09s.25.05.36.12l7.99,5.51c.1.07.18.17.24.28.06.11.09.24.09.37s-.03.26-.09.37c-.06.11-.14.21-.24.28l-7.99,5.51c-.11.07-.23.12-.36.12s-.26-.03-.37-.09c-.11-.07-.21-.16-.27-.28-.07-.12-.1-.25-.1-.39h0Z"
                    />
                    <path
                      className="icon_play"
                      d="m0,24C0,10.75,10.75,0,24,0s24,10.75,24,24-10.75,24-24,24S0,37.25,0,24ZM24,3.27c-5.5,0-10.77,2.18-14.66,6.07-3.89,3.89-6.07,9.16-6.07,14.66s2.18,10.77,6.07,14.66c3.89,3.89,9.16,6.07,14.66,6.07s10.77-2.18,14.66-6.07,6.07-9.16,6.07-14.66-2.18-10.77-6.07-14.66c-3.89-3.89-9.16-6.07-14.66-6.07Z"
                    />
                  </svg>
                </div>
                <div className="txt">産直ストーリーを見る</div>
              </button>
              <LazyLoadVideo
                videoProps={{
                  controls: false,
                  playsInline: true,
                  placeholder: 'blur',
                  autoPlay: true,
                  muted: true,
                  loop: true,
                }}
                videoSrc="/landing_renewal/img/voice/1_movie.mp4"
                thumbnailSrc="/landing_renewal/img/voice/1_movie.webp"
                thumbnailAlt="仕入れ食材 瀬戸内レモン 甲斐農園 甲斐さん"
                imageOverlay
              />
            </div>
            <div className="detail">
              <h3>
                圧倒的に鮮度の高い食材たちを
                <br />
                五感で｢おいしい｣と
                <br className="sp" />
                感じてもらえる料理に
              </h3>
              <p>
                このサービスで私がとくに注目しているのは
                <strong>「圧倒的な鮮度の高さ」</strong>
                です。色味、みずみずしさ、香りなど、箱を開いた瞬間から食材のよさが伝わってくる。旬の食材のポテンシャルを最大限に引き出して、料理に落とし込むことができます。また、同じ食材でも産地や特徴が異なる商品が豊富に用意されており、求める味わいや調理法などに応じて選択できるのも嬉しいところ。
                <br />
                <br />
                <button>
                  続きを読む{' '}
                  <img src="/landing_renewal/img/icon_plus.svg" alt="" />
                </button>
                <span className="hide">
                  量にかかわらず、<strong>1カート単位で利用料が一定</strong>
                  なのも非常に助かりますね。一般的な産直で送料を加味してしまうと、いまの原価ではとても収まりません。また、これまで使ったことのない食材を少量取り寄せて、スタッフと相談しながら新しいメニューを試作するといったことも気軽にできるので、とてもありがたいです。
                  <br />
                  <br />
                  商品ページには生産者の方々の思いやこだわりなども掲載されているから、
                  <strong>料理の背景にある“ストーリー”も付加価値</strong>
                  として、お客様に味わっていただけます。お客様には、単純に味覚だけでなく、五感で「おいしい」と感じていただきたいので。
                  <br />
                  <br />
                  このサービスを使うことで、五感にしっかりと訴えかける料理を提供できる──それが私にとって「ヤマトフードマーケット」の最大の魅力です。
                </span>
              </p>
              <div className="name">
                代官山ASO チェレステ 日本橋店
                <br />
                料理長 菊池<span>さん</span>
              </div>
              <div className="foodcont">
                <div className="product_img">
                  <Image
                    src="/landing_renewal/img/voice/1_producer_food.jpg"
                    alt="レモン"
                    width={500}
                    height={500}
                    sizes="33vw"
                  />
                </div>
                <div className="detail">
                  <div className="txt">
                    <div className="food_name">
                      <h4>仕入れ食材</h4> 瀬戸内レモン
                    </div>
                    <div className="producer_name">
                      <div className="place">広島県</div> 甲斐農園 甲斐
                      <span>さん</span>
                    </div>
                  </div>
                  <div className="producer_img">
                    <img
                      src="/landing_renewal/img/voice/1_producer.jpg"
                      alt="甲斐農園 甲斐さん"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className={`voicecont ${currentTab === 'voice2' ? 'current' : ''}`} data-voice="voice2">
          <div>
            <div className="sliderCont">
              <ul className="slider_voice">
                <li>
                  <Image
                    src="/landing_renewal/img/voice/2_1.jpg"
                    alt="オーナーシェフ 井口さん"
                    layout="fill"
                    objectFit="cover"
                  />
                </li>
                <li css="background-image: url(/landing_renewal/img/voice/2_2.jpg);">
                  <Image
                    src="/landing_renewal/img/voice/2_2.jpg"
                    alt="オーナーシェフ 井口さんの料理"
                    layout="fill"
                    objectFit="cover"
                  />
                </li>
              </ul>
            </div>
            <div className="movie_th">
              <button
                className="play"
                data-micromodal-trigger="modal-2"
                role="button"
              >
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                    <defs></defs>
                    <path
                      className="icon_play"
                      d="m20.86,29.51v-11.03c0-.14.03-.27.1-.39.07-.12.16-.22.27-.28.11-.07.24-.1.37-.09s.25.05.36.12l7.99,5.51c.1.07.18.17.24.28.06.11.09.24.09.37s-.03.26-.09.37c-.06.11-.14.21-.24.28l-7.99,5.51c-.11.07-.23.12-.36.12s-.26-.03-.37-.09c-.11-.07-.21-.16-.27-.28-.07-.12-.1-.25-.1-.39h0Z"
                    />
                    <path
                      className="icon_play"
                      d="m0,24C0,10.75,10.75,0,24,0s24,10.75,24,24-10.75,24-24,24S0,37.25,0,24ZM24,3.27c-5.5,0-10.77,2.18-14.66,6.07-3.89,3.89-6.07,9.16-6.07,14.66s2.18,10.77,6.07,14.66c3.89,3.89,9.16,6.07,14.66,6.07s10.77-2.18,14.66-6.07,6.07-9.16,6.07-14.66-2.18-10.77-6.07-14.66c-3.89-3.89-9.16-6.07-14.66-6.07Z"
                    />
                  </svg>
                </div>
                <div className="txt">産直ストーリーを見る</div>
              </button>
              <LazyLoadVideo
                videoProps={{
                  controls: false,
                  playsInline: true,
                  placeholder: 'blur',
                  autoPlay: true,
                  muted: true,
                  loop: true,
                }}
                videoSrc="/landing_renewal/img/voice/2_movie.mp4"
                thumbnailSrc="/landing_renewal/img/voice/2_movie.webp"
                thumbnailAlt="仕入れ食材 真牡蠣 海遊 伊藤さん"
                imageOverlay
              />
            </div>
            <div className="detail">
              <h3>
                求める食材を自由に組み合わせて、
                <br className="pc" />
                定額の利用料で取り寄せることができる
              </h3>
              <p>
                四季折々の食材を吟味して、お客様に「旬」を感じていただけるような料理を提供したい。そのうえで「おいしい！」と喜んでいただきたい──これこそが私の一番の願いであり、料理人という仕事の醍醐味でもあると思うんです。
                <br />
                <br />
                「産直」はその願いを実現する手段のひとつですが、日本各地に存在する素晴らしい食材を発掘するのは一苦労ですし、なにより個別に取り寄せるとなると、送料の負担も軽視できない問題になってきます。
                <br />
                <br />
                <button>
                  続きを読む{' '}
                  <img src="/landing_renewal/img/icon_plus.svg" alt="" />
                </button>
                <span className="hide">
                  その点、こちらのサービスは
                  <strong>
                    食材を取り寄せる際の利用料が1カートあたりで定額
                  </strong>
                  ですから、とてもリーズナブル。全国の優れた食材が集約されていて、それらを一括で配送してもらえるのですから、大助かりです。以前は選択肢に入ってこなかった食材を気兼ねなく使えるようになったことで、メニューづくりのフットワークも軽くなりました。
                  <br />
                  <br />
                  加えて、一般的な「産直」には生産者さんとの関係構築やその維持といった要素も絡んできますよね。ときには、それがある種の足かせになってしまう場合も。一方、こちらのサービスであれば、その時々でベストな食材を、
                  <strong>
                    関係性やお付き合いにとらわれることなく気軽に入手できる。
                  </strong>
                  このあたりもありがたいところです。
                  <br />
                  <br />
                  「ヤマトフードマーケット」は、理想とする店づくりを支えてくれる、大切なパートナーだと感じています。
                </span>
              </p>
              <div className="name">
                Concerto
                <br />
                オーナーシェフ 井口<span>さん</span>
              </div>
              <div className="foodcont">
                <div className="product_img">
                  <img
                    src="/landing_renewal/img/voice/2_producer_food.jpg"
                    alt="牡蠣"
                  />
                </div>
                <div className="detail">
                  <div className="txt">
                    <div className="food_name">
                      <h4>仕入れ食材</h4> 真牡蠣
                    </div>
                    <div className="producer_name">
                      <div className="place">宮城県</div> 海遊 伊藤
                      <span>さん</span>
                    </div>
                  </div>
                  <div className="producer_img">
                    <img
                      src="/landing_renewal/img/voice/2_producer.jpg"
                      alt="海遊 伊藤さん"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className={`voicecont voice3 ${currentTab === 'voice3' ? 'current' : ''}`} data-voice="voice3">
          <div>
            <div className="sliderCont">
              <ul className="slider_voice">
                <li>
                  <Image
                    src="/landing_renewal/img/voice/3_1.jpg"
                    alt="店主 橋本さん"
                    layout="fill"
                    objectFit="cover"
                  />
                </li>
                <li>
                  <Image
                    src="/landing_renewal/img/voice/3_2.jpg"
                    alt="店主 橋本さんの料理"
                    layout="fill"
                    objectFit="cover"
                  />
                </li>
              </ul>
            </div>
            <div className="movie_th">
              <button
                className="play"
                data-micromodal-trigger="modal-3"
                role="button"
              >
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                    <defs></defs>
                    <path
                      className="icon_play"
                      d="m20.86,29.51v-11.03c0-.14.03-.27.1-.39.07-.12.16-.22.27-.28.11-.07.24-.1.37-.09s.25.05.36.12l7.99,5.51c.1.07.18.17.24.28.06.11.09.24.09.37s-.03.26-.09.37c-.06.11-.14.21-.24.28l-7.99,5.51c-.11.07-.23.12-.36.12s-.26-.03-.37-.09c-.11-.07-.21-.16-.27-.28-.07-.12-.1-.25-.1-.39h0Z"
                    />
                    <path
                      className="icon_play"
                      d="m0,24C0,10.75,10.75,0,24,0s24,10.75,24,24-10.75,24-24,24S0,37.25,0,24ZM24,3.27c-5.5,0-10.77,2.18-14.66,6.07-3.89,3.89-6.07,9.16-6.07,14.66s2.18,10.77,6.07,14.66c3.89,3.89,9.16,6.07,14.66,6.07s10.77-2.18,14.66-6.07,6.07-9.16,6.07-14.66-2.18-10.77-6.07-14.66c-3.89-3.89-9.16-6.07-14.66-6.07Z"
                    />
                  </svg>
                </div>
                <div className="txt">産直ストーリーを見る</div>
              </button>
              <LazyLoadVideo
                videoProps={{
                  controls: false,
                  playsInline: true,
                  placeholder: 'blur',
                  autoPlay: true,
                  muted: true,
                  loop: true,
                }}
                videoSrc="/landing_renewal/img/voice/3_movie.mp4"
                thumbnailSrc="/landing_renewal/img/voice/3_movie.webp"
                thumbnailAlt="仕入れ食材 スナップエンドウ アグリスタイル 湯ノ口さん"
                imageOverlay
              />
            </div>
            <div className="detail">
              <h3>
                季節感を大切にする日本料理だからこそ
                <br />
                信頼できる食材との出会いは大きな喜び
              </h3>
              <p>
                このサービスの存在を知ったとき、まず思ったのは「ヤマトさんが面白いことを始められたな」という点。色んな産直サービスがあるなか、“運びのプロ”であるヤマトさんの産直がどんなものなのか、とても興味が湧いたのですぐ注文してみました。
                <br />
                <br />
                びっくりしましたね。これまでも個人的に生産者さんとやり取りして、
                <strong>
                  さまざまな食材を各地から取り寄せてきましたが、それらと遜色ない品質のものが届いた。
                </strong>
                「運送屋さんのやることだから、大したことないだろう」なんて気持ちも若干あったのですが、完全に覆されました。
                <br />
                <br />
                <button>
                  続きを読む{' '}
                  <img src="/landing_renewal/img/icon_plus.svg" alt="" />
                </button>
                <span className="hide">
                  日本料理は四季の移ろいを大切にして、折々の食材の味を最大限に引き出すのが基本。さらに同じ食材でも、地域や時期が違えばそれぞれ味が異なるし、調理の仕方も変わってきます。
                  <br />
                  <br />
                  だからこそ、素材の目利きが重要なのですが、
                  <strong>
                    このサービスは最初の注文から間違いない食材が届きました。
                  </strong>
                  その生産者さんに直接お会いしたことはなくても、“生産のプロ”としての丁寧な仕事ぶりや思いがしっかり伝わってくる。
                  <br />
                  <br />
                  僕は“気持ちの入っていない食材”を触るのが一番イヤなんです。
                  <strong>
                    ヤマトフードマーケットの食材はどれも信頼できる
                  </strong>
                  ので、“料理のプロ”である自分はいかに食材の魅力を引き出し、おいしい料理に仕上げるかに集中できる。1日中料理のことばかり考えている僕のような人間にとって、こんなにテンションが上がることはありません。
                  <br />
                  <br />
                  まずは一度、このサービスを試してみてほしいです。きっと、新しい食材との出会いが待っていると思います。
                </span>
              </p>
              <div className="name">
                日本料理 一凛
                <br />
                店主 橋本<span>さん</span>
              </div>
              <div className="foodcont">
                <div className="product_img">
                  <img
                    src="/landing_renewal/img/voice/3_producer_food.jpg"
                    alt="スナップエンドウ"
                  />
                </div>
                <div className="detail">
                  <div className="txt">
                    <div className="food_name">
                      <h4>仕入れ食材</h4> スナップエンドウ
                    </div>
                    <div className="producer_name">
                      <div className="place">鹿児島県</div>
                      アグリスタイル 湯ノ口<span>さん</span>
                    </div>
                  </div>
                  <div className="producer_img">
                    <img
                      src="/landing_renewal/img/voice/3_producer.jpg"
                      alt="アグリスタイル 湯ノ口さん"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <ul className="tab" id="bottomTab">
          <li>
            <button className={`tab-btn ${currentTab === 'voice1' ? 'current' : ''}`} data-target="voice1">
              <div
                className="img"
                css="background-image: url(/landing_renewal/img/voice/1_1.jpg);"
              ></div>
              <div className="num">
                Voice <strong>1</strong>
              </div>
              <div className="name">
                <div className="restaurant">
                  代官山ASO チェレステ
                  <br />
                  日本橋店
                </div>
                <div className="chef">
                  料理長 菊池<span>さん</span>
                </div>
              </div>
            </button>
          </li>
          <li>
            <button className={`tab-btn ${currentTab === 'voice2' ? 'current' : ''}`} data-target="voice2">
              <div
                className="img"
                css="background-image: url(/landing_renewal/img/voice/2_1.jpg);"
              ></div>
              <div className="num">
                Voice <strong>2</strong>
              </div>
              <div className="name">
                <div className="restaurant">Concerto</div>
                <div className="chef">
                  オーナーシェフ 井口<span>さん</span>
                </div>
              </div>
            </button>
          </li>
          <li>
            <button className={`tab-btn ${currentTab === 'voice3' ? 'current' : ''}`} data-target="voice3">
              <div
                className="img"
                css="background-image: url(/landing_renewal/img/voice/3_1.jpg);"
              ></div>
              <div className="num">
                Voice <strong>3</strong>
              </div>
              <div className="name">
                <div className="restaurant">日本料理 一凛</div>
                <div className="chef">
                  店主 橋本<span>さん</span>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </section>
      <section id="section_regist">
        <div>
          <h2>
            <span>飲食店限定</span>
            <br className="sp" />
            カンタン
            <strong>
              <ruby>
                無料<rt>・・</rt>
              </ruby>
              登録
            </strong>
            で<br className="sp" />
            <strong>産直スタート!</strong>
          </h2>
          <a
            className="btn_resist"
            onClick={handleOpenSignUpModal}
            css="cursor: pointer;"
          >
            <img src="/landing_renewal/img/icon_push.svg" alt="" />
            会員登録はこちら
          </a>
          <img
            className="coupon"
            src="/landing_renewal/img/cv_free_fee.png"
            alt="新規ご登録特典60日間利用料無料！"
          />
          <div className="inquiry">
            <h3>
              <span>
                ご登録を検討している
                <br className="sp" />
                飲食店の皆様へ
              </span>
            </h3>
            <p>お気軽にお問い合わせください</p>
            <ul>
              <li>
                <a className="tel" href="tel:03-4564-2161">
                  <img src="/landing_renewal/img/icon_tell_w.svg" alt="tel" />
                  03-4564-2161
                </a>
                <dl>
                  <dt>営業時間</dt>
                  <dd>
                    <span className="time">9:00-18:00</span>{' '}
                    <span className="note">（土日祝含む）</span>
                  </dd>
                </dl>
              </li>
              <li>
                <a
                  className="mail"
                  href="m&#97;i&#108;t&#111;:y&#102;m&#48;&#51;0&#49;&#64;k&#117;&#114;&#111;&#110;e&#107;&#111;&#121;&#97;&#109;&#97;&#116;&#111;&#46;&#99;&#111;&#46;&#106;&#112;"
                >
                  <img src="/landing_renewal/img/icon_mail_w.svg" alt="mail" />
                  メールでお問い合わせ
                </a>
                <dl>
                  <dd>
                    <span className="time">
                      y&#102;m&#48;&#51;0&#49;&#64;k&#117;&#114;&#111;&#110;e&#107;&#111;&#121;&#97;&#109;&#97;&#116;&#111;&#46;&#99;&#111;&#46;&#106;&#112;
                    </span>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
        <div className="modal-overlay" tabIndex={-1} data-micromodal-close>
          <div className="modal-container" role="dialog" aria-modal="true">
            <button
              className="modal-close"
              aria-label="close modal"
              data-micromodal-close
            ></button>

            <div className="modal-content">
              <video
                controls
                playsInline
                muted
                preload="none"
                poster="/landing_renewal/img/voice/1_movie.webp"
              >
                <source
                  src="/landing_renewal/img/voice/1_movie.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
      <div className="modal micromodal-slide" id="modal-2" aria-hidden="true">
        <div className="modal-overlay" tabIndex={-1} data-micromodal-close>
          <div className="modal-container" role="dialog" aria-modal="true">
            <button
              className="modal-close"
              aria-label="close modal"
              data-micromodal-close
            ></button>

            <div className="modal-content">
              <video
                controls
                playsInline
                muted
                preload="none"
                poster="/landing_renewal/img/voice/2_movie.webp"
              >
                <source
                  src="/landing_renewal/img/voice/2_movie.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
      <div className="modal micromodal-slide" id="modal-3" aria-hidden="true">
        <div className="modal-overlay" tabIndex={-1} data-micromodal-close>
          <div className="modal-container" role="dialog" aria-modal="true">
            <button
              className="modal-close"
              aria-label="close modal"
              data-micromodal-close
            ></button>

            <div className="modal-content">
              <video
                controls
                playsInline
                muted
                preload="none"
                poster="/landing_renewal/img/voice/3_movie.webp"
              >
                <source
                  src="/landing_renewal/img/voice/3_movie.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
      <ul className="follow">
        <li>
          <a onClick={handleOpenSignUpModal} css="cursor: pointer;">
            <span>会員登録</span>
          </a>
        </li>
        <li>
          <a href="tel:03-4564-2161">
            <img src="/landing_renewal/img/icon_tell_w.svg" alt="tel" />
          </a>
        </li>
        <li>
          <a
            href="m&#97;i&#108;t&#111;:y&#102;m&#48;&#51;0&#49;&#64;k&#117;&#114;&#111;&#110;e&#107;&#111;&#121;&#97;&#109;&#97;&#116;&#111;&#46;&#99;&#111;&#46;&#106;&#112;"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/landing_renewal/img/icon_mail_w.svg" alt="mail" />
          </a>
        </li>
      </ul>
    </main>
  )
}
