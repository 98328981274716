import type { NextPageWithLayout, GetStaticProps } from 'next'
import { ReactNode } from 'react'
import Layout from 'src/components/layout/Guest'
import Content from 'src/pages/landing'

const LandingPage: NextPageWithLayout = (): JSX.Element => {
  return <Content />
}

LandingPage.getLayout = (page: JSX.Element): ReactNode => {
  const wrappedLayout = <Layout>{page}</Layout>
  return wrappedLayout
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {},
  }
}

export default LandingPage
