import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect } from 'react'
import { storageKey } from 'src/consts/storageKey'
import { Header } from './Header'
import { Content } from './Content'
import { useSignUpModal } from 'src/components/SignUpModal/useSignUpModal'
import { SignUpModal } from 'src/components/SignUpModal/SignUpModal'

const Landing = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0()
  const { isOpenSignUpModal, handleBuyerSignUp, handleOpenSignUpModal, handleCloseSignUpModal } = useSignUpModal(
    loginWithRedirect
  )

  useEffect(() => {
    sessionStorage.removeItem(storageKey.BUTTON_ACTIVE)
  }, [])

  const handleLogin = useCallback(() => {
    loginWithRedirect({
      type: 'login',
    })
  }, [loginWithRedirect])

  return (
    <>
      <Header handleOpenSignUpModal={handleOpenSignUpModal} handleLogin={handleLogin} />
      <Content handleOpenSignUpModal={handleOpenSignUpModal} />
      {isOpenSignUpModal && <SignUpModal onSignUp={handleBuyerSignUp} onClose={handleCloseSignUpModal} />}
    </>
  )
}

export default Landing
